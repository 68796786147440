import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Divider,
  CircularProgress,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Alert } from "../../../../../../components/app/Alert";
import { SubTitlePage } from "../../../../../../components/app/Title";
import { updateUser, updateEssay, listUsers, listEssays } from "../../../services";
import { Drawer, DrawerHeader } from "../../styled/Drawer";

export const DrawerArea = (props) => {
  const { open, setOpen, id, dataType, refreshList } = props;

  const [data, setData] = useState(null);
  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const closeDrawer = () => {
    setOpen(!open);
    refreshList(); // Chame refreshList ao fechar o Drawer
  };

  const load = useCallback(async () => {
    try {
      setOpen(open);
      setMessageError("");
      setOpenError(false);

      let responseData = {};
      if (dataType === "users") {
        const users = await listUsers();
        responseData = users.find((user) => user.id === id);
      } else {
        const essays = await listEssays();
        responseData = essays.find((essay) => essay.id === id);
      }
      setData(responseData);
    } catch (error) {
      console.error("Erro ao carregar os dados:", error);
      setMessageError(error.message);
      setOpenError(true);
    }
  }, [id, open, dataType]);

  useEffect(() => {
    if (id) {
      load();
    }
  }, [id, load]);

  const handleSave = async () => {
    try {
      if (dataType === "users") {
        await updateUser(id, data);
      } else {
        await updateEssay(id, data.content); // Enviando apenas a string de conteúdo
      }
      closeDrawer();
    } catch (error) {
      console.error("Erro ao salvar os dados:", error);
      setMessageError("Erro ao salvar os dados.");
      setOpenError(true);
    }
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Drawer anchor="right" open={open} onClose={closeDrawer}>
      <DrawerHeader>
        <Box display="flex" alignItems="center" justifyContent="end" width="100%" px={2}>
          <Button color="secondary" variant="outlined" startIcon={<ArrowBackIcon />} onClick={closeDrawer}>
            Voltar
          </Button>
        </Box>
      </DrawerHeader>

      <Divider sx={{ borderColor: (theme) => theme.palette.background.default, marginBottom: 4 }} />

      <Box px={4} py={2}>
        {openError && (
          <Box mb={4}>
            <Alert message={messageError} open={openError} />
          </Box>
        )}

        {data ? (
          <Box mx={4} mb={4}>
            <SubTitlePage>Editar {dataType === "users" ? "Usuário" : "Redação"}</SubTitlePage>

            <Divider sx={{ marginBottom: 4 }} />

            {dataType === "users" ? (
              <>
                <TextField
                  label="Nome"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Email"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Tipo de Usuário"
                  name="user_type"
                  value={data.user_type}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </>
            ) : (
              <TextField
                label="Conteúdo da Redação"
                name="content"
                value={data.content}
                onChange={handleChange}
                fullWidth
                margin="normal"
                multiline
                rows={6} // Aqui estamos usando um textarea para grandes textos
              />
            )}

            <Button color="primary" variant="contained" onClick={handleSave} fullWidth sx={{ mt: 4 }}>
              Salvar
            </Button>
          </Box>
        ) : (
          <Box my={5} textAlign="center">
            <CircularProgress color="secondary" />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

