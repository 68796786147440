import React from "react";

/* Material */
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

export function RadioGroup(props) {
  const { label, name, value, onChange, items } = props;

  return (
    <FormControl component="fieldset">
      <FormLabel
        component="legend"
        sx={{
          marginBottom: (theme) => theme.spacing(1),
        }}
      >
        {label}
      </FormLabel>

      <MuiRadioGroup row name={name} value={value} onChange={onChange}>
        {items.map(function (item, index) {
          return (
            <FormControlLabel
              key={index}
              value={item.value}
              control={<Radio />}
              label={item.title}
              sx={{
                width: {
                  xs: "100%",
                  md: "auto",
                },
              }}
            />
          );
        })}
      </MuiRadioGroup>
    </FormControl>
  );
}
