import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  Paper,
  TextField,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { fetchEssayData, updateEssay } from "../../../services";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "850px",
  maxHeight: "90vh",  // Limita a altura máxima do modal
  overflow: "auto",  // Permite scroll global no modal
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
};

export const EditEssayModal = ({ open, handleClose, essayId, refreshTable }) => {
  const [essayData, setEssayData] = useState(null);
  const [totalGrade, setTotalGrade] = useState(0);

  useEffect(() => {
    if (open && essayId) {
      fetchData(essayId);
    }
  }, [open, essayId]);

  const fetchData = async (id) => {
    try {
      const data = await fetchEssayData(id);
      setEssayData(data);
      calculateTotalGrade(data.item_grades);
    } catch (error) {
      console.error('Erro ao buscar dados da redação:', error);
    }
  };

  const calculateTotalGrade = (grades) => {
    const total = grades.reduce((acc, grade) => acc + grade.grade, 0);
    setTotalGrade(total);
  };

  const handleGradeChange = (index, value) => {
    const updatedGrades = [...essayData.item_grades];
    const validatedValue = Math.max(0, Math.min(200, Number(value))); // Validação da nota
    updatedGrades[index].grade = validatedValue;
    setEssayData({ ...essayData, item_grades: updatedGrades });
    calculateTotalGrade(updatedGrades);
  };

  const handleFeedbackChange = (field, value) => {
    setEssayData({ ...essayData, [field]: value });
  };

  const handleSave = async () => {
    try {
      await updateEssay(
        essayData.id,
        {
          general_comment: essayData.general_comment,
          essay_average_grade: totalGrade,
          audio: essayData.audio,
        },
        essayData.item_grades.map(({ id, grade, feedback, recommendation, audio }) => ({
          id,
          grade,
          feedback,
          recommendation,
          audio,
        }))
      );
      handleClose();
      refreshTable();
    } catch (error) {
      console.error('Erro ao salvar dados da redação:', error);
    }
  };

  if (!essayData) return null;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" mb={2}>
          Redação
        </Typography>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
          {/* Texto da Redação */}
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: '80vh' }}>
            <Paper
              sx={{
                p: 2,
                flexGrow: 1,
                overflowY: "auto",  // Scroll para o texto da redação
              }}
            >
              <Typography>{essayData.content}</Typography>
            </Paper>
          </Box>

          {/* Competências e Nota Total */}
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
            <Grid container spacing={2}>
              {essayData.item_grades.map((item, index) => (
                <Grid item xs={12} md={6} key={item.id}>
                  <Card sx={{ minWidth: '200px' }}>
                    <CardContent>
                      <Typography variant="body1" gutterBottom>
                        Competência {index + 1}
                      </Typography>
                      <TextField
                        fullWidth
                        label="Pontuação"
                        variant="outlined"
                        type="number"
                        value={item.grade}
                        onChange={(e) => handleGradeChange(index, e.target.value)}
                        sx={{ mb: 1 }}
                        inputProps={{ min: 0, max: 200 }} // Validação diretamente no input
                      />

                      {/* <TextField
                        fullWidth
                        label="Comentário/Sugestão"
                        variant="outlined"
                        value={item.feedback}
                        onChange={(e) =>
                          handleGradeChange(index, "feedback", e.target.value)
                        }
                      />*/}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Card sx={{ mt: 2, bgcolor: "grey.300", p: 2 }}>
                  <Typography variant="body1" gutterBottom>
                    Nota total
                  </Typography>
                  <Typography variant="h4">{totalGrade}/1000</Typography>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Feedback Geral */}
        <TextField
          fullWidth
          label="Feedback"
          variant="outlined"
          multiline
          rows={3} // Permite várias linhas para um feedback maior
          value={essayData.general_comment}
          onChange={(e) => handleFeedbackChange("general_comment", e.target.value)}
          sx={{ mt: 2, mb: 2 }}
        />

        {/* Botões de Ação */}
        <Box display="flex" justifyContent="space-between">
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

