/* React */
import React, { useState } from "react";

/* Material */
import {
  Box,
  TableHead,
  TableBody,
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableContainer,
} from "@mui/material/";
import { styled } from "@mui/material/styles";

/* Styles */
const PREFIX = "table";

export const classesTable = {
  root: `${PREFIX}-root`,
  containerCell: `${PREFIX}-containerCell`,
  containerCellActive: `${PREFIX}-containerCellActive`,
  containerCellTitle: `${PREFIX}-containerCellTitle`,
  avatar: `${PREFIX}-avatar`,
};

export const StylesMuiTableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${classesTable.root}`]: {
    whiteSpace: "nowrap",
    borderBottom: "none",
    padding: 0,
  },
  [`& .${classesTable.containerCellTitle}`]: {
    whiteSpace: "nowrap",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: 50,
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
  },
  [`& .${classesTable.containerCellActive}`]: {
    flexGrow: 1,
    cursor: "pointer",
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.table.background,
    borderLeft: `5px solid ${theme.palette.table.active}`,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    minHeight: 50,
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
  },
  [`& .${classesTable.containerCell}`]: {
    cursor: "pointer",
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.table.background,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    minHeight: 50,
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
  },
  [`& .${classesTable.avatar}`]: {
    width: 36,
    height: 36,
  },
}));

function TableHeadRow(props) {
  const { data } = props;

  return (
    <React.Fragment>
      {data.length > 0 ? (
        data.map((item, index) => {
          let { title, align, size } = item;

          return (
            <React.Fragment key={index}>
              {title === null ? (
                <MuiTableCell className={classesTable.containerCell} />
              ) : (
                <StylesMuiTableCell
                  className={classesTable.root}
                  sx={{
                    width: `${size ? size : "auto"} !important`,
                  }}
                >
                  <Box
                    component="div"
                    className={classesTable.containerCellTitle}
                    sx={{
                      "@media screen and (min-width: 900px)": {
                        justifyContent: `${align ? align : "right"}`,
                      },
                    }}
                  >
                    {title}
                  </Box>
                </StylesMuiTableCell>
              )}
            </React.Fragment>
          );
        })
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
}

export const Table = (props) => {
  const TableRow = props.component;
  const DrawerApp = props.drawer;
  const refreshList = props.refreshList;
  const rows = props.rows || [];
  const columns = props.columns || [];
  const idPage = props.idPage || null;

  // states
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  function openDrawer(id) {
    setOpen(true);
    setId(id);
  }

  return (
    <React.Fragment>
      <TableContainer>
        <MuiTable>
          <TableHead>
            <MuiTableRow>
              <TableHeadRow data={columns || []} />
            </MuiTableRow>
          </TableHead>

          <TableBody>
            {rows.length > 0 ? (
              rows.map(function (row, index) {
                return (
                  <TableRow
                    key={index}
                    row={row}
                    openDrawer={() => openDrawer(row.user_id)}
                  />
                );
              })
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>

      {DrawerApp ? (
        <React.Fragment>
          <DrawerApp
            refreshList={refreshList}
            open={open}
            setOpen={setOpen}
            id={id}
            idPage={idPage}
          />
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};
