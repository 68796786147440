import React from "react";

/* Router */
import { Routes, Route, Navigate } from "react-router-dom";

/* OAuth pages */
import { Login } from "../../pages/OAuth/Login";
import { Logout } from "../../pages/OAuth/Logout";

/* Private pages */
import { Home } from "../../pages/Dashboard/Home";
import { Users } from "../../pages/Dashboard/Users";
import { Settings } from "../../pages/Dashboard/Settings";
import { DirectMail } from "../../pages/Dashboard/DirectMail";

/* Components */
import { OAuthRoutes } from "../../components/routes/OAuthRoutes";
import { PrivateRoutes } from "../../components/routes/PrivateRoutes";
import { RedGiz } from "../../pages/Dashboard/Redgiz";

export const AppRoutes = () => {
  return (
    <Routes>
      {/* ================= OAUTH ROUTES ================= */}
      <Route element={<OAuthRoutes />}>
        <Route path="/login" element={<Login />} />
      </Route>

      <Route path="/logout" element={<Logout />} />

      {/* ================= PRIVATE ROUTES ================= */}
      <Route element={<PrivateRoutes />}>
        <Route path="/inicio" element={<Home />} />
        <Route path="/redgiz" element={<RedGiz />} />
        <Route path="/usuarios" element={<Users />} />
        <Route path="/mala-direta" element={<DirectMail />} />
        <Route path="/conta" element={<Settings />} />
      </Route>

      {/* ================= ERRORS ================= */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
