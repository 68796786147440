import React from "react";

/* Material */
import { Typography } from "@mui/material";

export const TitlePage = ({ children, ...other }) => {
  return (
    <Typography
      component="h1"
      variant="h4"
      color="primary"
      mb={5}
      gutterBottom
      sx={{
        color: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.primary.main
            : "#f9f9f9",
      }}
      {...other}
    >
      {children}
    </Typography>
  );
};

export const SubTitlePage = ({ children, ...other }) => {
  return (
    <Typography
      component="h2"
      variant="h6"
      fontWeight="600"
      mb={3}
      gutterBottom
      {...other}
    >
      {children}
    </Typography>
  );
};
