import { admin } from "../../services/api";

async function VerifyToken(token) {
  try {
    let response = await admin.get("/verify-token", {
      headers: { authorization: `Authorization ${token}` },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export { VerifyToken };
