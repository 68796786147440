import { api, admin } from "../../../services/api";

async function ListUser() {
  try {
    let response = await api.get(
      `admin/user/collection?limit=${9999999}&index=${0}&search=`
    );

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

async function SendEmail(to, subject, message) {
  try {
    let response = await admin.post("/mail", {
      to,
      subject,
      message,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export { ListUser, SendEmail };
