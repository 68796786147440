import axios from "axios";

let baseURLApi = process.env.REACT_APP_API_URL;
let baseURLAdmin = process.env.REACT_APP_ADMIN_URL;

export const api = axios.create({
  baseURL: baseURLApi,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const admin = axios.create({
  baseURL: baseURLAdmin,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
