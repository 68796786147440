import React, { useState, useEffect } from "react";
import { Box, Button, ButtonGroup } from "@mui/material";
import { Sidebar } from "../../../components/app/Sidebar";
import { BoxPaper } from "../../../components/app/Box";
import { Scrollbar } from "../../../components/app/Scrollbar";
import { DataTable } from "./DataTable";
import { DrawerAdd } from "./components/Drawer/Add";
import AddIcon from "@mui/icons-material/Add";
import { listUsers, listEssays } from "./services";

export const RedGiz = () => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [dataType, setDataType] = useState("users"); // users or essays

  const fetchData = async () => {
    try {
      if (dataType === "users") {
        const users = await listUsers();
        setData(users);
      } else {
        const essays = await listEssays();
        setData(essays);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  // Função refreshList para recarregar os dados
  const refreshList = () => {
    fetchData(); // Recarrega os dados chamando fetchData
  };

  useEffect(() => {
    fetchData();
  }, [dataType]);

  const openDrawer = () => {
    setOpen(!open);
  };

  return (
    <Sidebar>
      <Box px={4}>
        {user.permissao === "1" || user.permissao === "2" ? (
          <Box
            mt={4}
            sx={{
              textAlign: {
                xs: "start",
                md: "end",
              },
            }}
          >
            <Button color="success" variant="contained" endIcon={<AddIcon />} onClick={openDrawer}>
              Novo
            </Button>
          </Box>
        ) : null}

        <ButtonGroup variant="contained" color="primary" aria-label="outlined primary button group" sx={{ mb: 2, mt: 2 }}>
          <Button onClick={() => setDataType("users")}>Usuários</Button>
          <Button onClick={() => setDataType("essays")}>Redações</Button>
        </ButtonGroup>

        <BoxPaper>
          <Box minHeight="100vh">
            <Scrollbar>
              <DataTable data={data} dataType={dataType} refreshList={refreshList} />
            </Scrollbar>
          </Box>
        </BoxPaper>
      </Box>

      <DrawerAdd open={open} setOpen={setOpen} />
    </Sidebar>
  );
};

