/* Material */
import { styled } from "@mui/material/styles";

import bannerLogin from "../../assets/img/banner-login.png";

const PREFIX = "oauth";

export const classes = {
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`,
  bannerSignIn: `${PREFIX}-bannerSignIn`,
  form: `${PREFIX}-form`,
  paper: `${PREFIX}-paper`,
};

export const StylesOAuth = styled("div")(({ theme }) => ({
  [`.${classes.root}`]: {
    height: "100%",
  },
  [`.${classes.main}`]: {
    minHeight: "100vh",
  },
  [`.${classes.bannerSignIn}`]: {
    backgroundImage:
      theme.palette.mode === "light"
        ? `linear-gradient(rgb(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${bannerLogin})`
        : `linear-gradient(rgb(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${bannerLogin})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "left bottom",
  },
  [`.${classes.form}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",

    [theme.breakpoints.up("sm")]: {
      justifyContent: "end",
    },
  },
  [`.${classes.paper}`]: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));
