import axios from 'axios';

const API_URL = process.env.REACT_APP_REDGIZ_API_URL;
const ADMIN_KEY = process.env.REACT_APP_REDGIZ_ADMIN_KEY;

const getHeaders = () => ({
  headers: {
    'key': ADMIN_KEY,
    'Content-Type': 'application/json',
  }
});

// Função para listar todos os usuários com paginação
export const listUsers = async (limit = 25, skip = 0) => {
  try {
    const response = await axios.get(`${API_URL}admin/users?limit=${limit}&skip=${skip}`, getHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao listar usuários:', error);
    throw error;
  }
};

// Função para deletar um usuário por ID
export const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`${API_URL}admin/users/${userId}`, getHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao deletar usuário:', error);
    throw error;
  }
};

// Função para editar um usuário por ID
export const updateUser = async (userId, userInfo) => {
  try {
    const response = await axios.put(`${API_URL}admin/users/${userId}`, userInfo, getHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar usuário:', error);
    throw error;
  }
};

// Função para listar todas as redações com paginação
export const listEssays = async (limit = 25, skip = 0) => {
  try {
    const response = await axios.get(`${API_URL}admin/essays_details?limit=${limit}&skip=${skip}`, getHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao listar redações:', error);
    throw error;
  }
};

// Função para deletar uma redação por ID
export const deleteEssay = async (essayId) => {
  try {
    const response = await axios.delete(`${API_URL}admin/essays/${essayId}`, getHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao deletar redação:', error);
    throw error;
  }
};

// Função para buscar os dados de uma redação por ID
export const fetchEssayData = async (essayId) => {
  try {
    const response = await axios.get(`${API_URL}admin/feedback/${essayId}`, getHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar dados da redação:', error);
    throw error;
  }
};

// Função para atualizar o feedback e item grades de uma redação por ID
export const updateEssay = async (essayId, feedbackData, itemGradesData) => {
  try {
    // Atualiza o feedback
    const feedbackResponse = await axios.patch(
      `${API_URL}admin/feedback/${essayId}`,
      feedbackData,
      getHeaders()
    );

    // Atualiza cada item_grade
    const itemGradeResponses = await Promise.all(itemGradesData.map(itemGrade =>
      axios.patch(
        `${API_URL}admin/item_grade/${itemGrade.id}`,
        itemGrade,
        getHeaders()
      )
    ));

    return {
      feedback: feedbackResponse.data,
      itemGrades: itemGradeResponses.map(res => res.data)
    };
  } catch (error) {
    console.error('Erro ao atualizar redação:', error);
    throw error;
  }
};

