import React, { useState } from "react";
import {
  Box,
  Paper,
  InputBase,
  Divider,
  Typography,
  TablePagination,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { Table } from "../components/Table";
import { Alert } from "../../../../components/app/Alert";
import { TitlePage } from "../../../../components/app/Title";
import { TableRow } from "../components/TableRow";
import { DrawerArea } from "../components/Drawer/Area";
import { EditEssayModal } from "./../components/Drawer/EditEssayModal"; // Importe o modal de edição

const getColumns = (dataType) => {
  if (dataType === "users") {
    return [
      { title: "Nome", align: "left" },
      { title: "Email", align: "center" },
      { title: "Tipo de Usuário", align: "center" },
    ];
  } else {
    return [
      { title: "Username", align: "left" },
      { title: "Titulo", align: "center" },
      { title: "Nota final", align: "center" },
      { title: "Conteúdo", align: "center" },
    ];
  }
};

export const DataTable = ({ data, dataType, refreshList }) => {
  const [search, setSearch] = useState("");
  const [tempSearch, setTempSearch] = useState("");
  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);
  const [qtd, setQtd] = useState(data.length);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [openModal, setOpenModal] = useState(false);
  const [selectedEssayId, setSelectedEssayId] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value));
    setPage(1);
  };

  const handleChangeSearch = (event) => {
    setTempSearch(event.target.value);
  };

  const deleteSearch = () => {
    setTempSearch("");
    setSearch("");
    setPage(1);
  };

  const submitSearch = () => {
    setSearch(tempSearch);
    setPage(1);
  };

  const handleEditClick = (essayId) => {
    setSelectedEssayId(essayId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEssayId(null);
  };

  const tableColumns = getColumns(dataType);

  return (
    <React.Fragment>
      <TitlePage>{dataType === "users" ? "Usuários" : "Redações"}</TitlePage>
      {/*
      <Box mb={4}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={qtd}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Box my={5} sx={{ display: "flex", justifyContent: "center" }}>
        <Paper sx={{ p: "6px 4px", display: "flex", alignItems: "center", maxWidth: "800px", width: "100%" }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={`Pesquisar por ${dataType === "users" ? "nome" : "Titulo"}`}
            inputProps={{ "aria-label": `Pesquisar por ${dataType === "users" ? "nome" : "Titulo"}` }}
            value={tempSearch}
            onChange={handleChangeSearch}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                submitSearch();
              }
            }}
          />

          <IconButton onClick={submitSearch} type="submit" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>

          {tempSearch !== "" ? (
            <React.Fragment>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton onClick={deleteSearch} color="secondary" sx={{ p: "10px" }} aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </React.Fragment>
          ) : null}
        </Paper>
      </Box> */}

      <Alert message={messageError} open={openError} />

      {data.length > 0 ? (
        <React.Fragment>
          <Table
            columns={tableColumns}
            component={TableRow}
            rows={data}
            drawer={DrawerArea}
            dataType={dataType}
            refreshList={refreshList}
            onEditClick={handleEditClick} // Passa a função de edição para a tabela
          />
        </React.Fragment>
      ) : (
        <Box textAlign="center" mt={4} mb={5} px={2}>
          <AutoStoriesIcon fontSize="large" color="secondary" />
          <Typography component="div" variant="h6" fontWeight={600} color="secondary" mt={1}>
            Nenhum registro encontrado.
          </Typography>
        </Box>
      )}

      <Box mt={4}>
        <TablePagination
          sx={{ display: "flex", justifyContent: "center" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={qtd}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      {/* Modal de edição */}
      <EditEssayModal
        open={openModal}
        handleClose={handleCloseModal}
        essayId={selectedEssayId}
        refreshTable={refreshList}
      />
    </React.Fragment>
  );
};

