import { admin } from "../../../services/api";

import { getToken } from "../../../oauth/fx/token";

export const getUser = async () => {
  try {
    const token = getToken();

    if (token) {
      let response = await admin.get("/user-info", {
        headers: {
          authorization: `Authorization ${token}`,
        },
      });

      const data = response.data;

      return data;
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
};

export const List = async (page, limit, search) => {
  try {
    let response = await admin.get("/user-pagination/collection", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export async function Show(id) {
  try {
    let response = await admin.get("user-pagination", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export async function StatusAccount(checked, id) {
  try {
    let response = await admin.put(`/user/status/${id}/`, { checked });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export const CreateUser = async (nome, email, login, senha, permissao) => {
  try {
    let response = await admin.post("/oauth", {
      nome,
      email,
      login,
      senha,
      foto: "user.png",
      permissao,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const DeleteAccount = async (id) => {
  try {
    let response = await admin.delete(`/oauth/${id}`);

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
