/* React */
import React, { useState } from "react";

/* Router */
import { useNavigate } from "react-router-dom";

/* Material ui */
import { Box, Grid, Typography, Paper } from "@mui/material";

/* Icons */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/* Components */
import FormControl from "../../../components/app/Form/FormControl";
import useForm from "../../../components/app/Form/FormControl/useForm";
import { Controls } from "../../../components/app/Form/Controls";
import { Alert } from "../../../components/app/Alert";

/* Context */
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

/* Styles */
import { StylesOAuth, classes } from "../styles";

const initialValues = {
  login: "",
  password: "",
  remember: false,
};

export function Login() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const isEmpty = (value) => {
    const result = value ? "" : "Preencha este campo.";
    return result;
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("login" in fieldValues) {
      temp.login = isEmpty(fieldValues.login);
    }

    if ("password" in fieldValues) {
      temp.password = isEmpty(fieldValues.password);
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    // setValues,
    errors,
    setErrors,
    handleInputChange,
    handleInputBlur,
  } = useForm(initialValues, true, validate);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setMessageError("");
    setOpenError(false);

    setTimeout(async () => {
      try {
        if (validate()) {
          let response = await auth.authenticate(
            values.login,
            values.password,
            values.remember
          );

          // validates if there was an error with the request
          if (response.error_message) {
            setMessageError(response.error_message);
            setOpenError(true);
          } else {
            navigate("/inicio");
          }
        } else {
          setMessageError("Por favor, corrija os campos em vermelho.");
          setOpenError(true);
        }
      } catch (error) {
        // internal error
        setMessageError("Ocorreu um erro inesperado na sessão.");
        setOpenError(true);
      }
    }, 100);
  };

  return (
    <StylesOAuth className={classes.root}>
      <Grid container component="main" className={classes.main}>
        <Grid item xs={12} sm={4} md={7} className={classes.bannerSignIn} />

        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={0}
          square
          className={classes.form}
        >
          <Box className={classes.paper}>
            <Box textAlign="start" width="100%" mb={4}>
              <Typography
                variant="h5"
                component="h1"
                fontWeight="700"
                gutterBottom
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.primary.main
                      : "#f1f1f1",
                }}
              >
                É bom te ver!
              </Typography>

              <Typography
                variant="p"
                component="div"
                color="secondary"
                gutterBottom
              >
                Digite seu e-mail e senha para entrar
              </Typography>
            </Box>

            <FormControl onSubmit={handleSubmit}>
              <Controls.Input
                autoComplete="new-email"
                label="Login"
                name="login"
                value={values.login}
                error={errors.login}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />

              <Controls.Input
                autoComplete="new-password"
                label="Senha"
                type="password"
                name="password"
                value={values.password}
                error={errors.password}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />

              <Box mb={4}>
                <Controls.Checkbox
                  color="active"
                  name="remember"
                  label="Mantenha-me conectado"
                  value={values.remember}
                  onChange={handleInputChange}
                />
              </Box>

              {openError ? (
                <Box width="100%">
                  <Alert message={messageError} open={openError} />
                </Box>
              ) : (
                <React.Fragment></React.Fragment>
              )}

              <Controls.Button
                color="primary"
                variant="contained"
                type="submit"
                width="100%"
                text="Prosseguir"
                endIcon={<ArrowForwardIcon />}
              ></Controls.Button>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </StylesOAuth>
  );
}
