import React from "react";

// material
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const PREFIX = "scroll";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  [`& .${classes.container}`]: {
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
}));

export const Scrollbar = ({ children }) => {
  return (
    <Root className={classes.root}>
      <Box className={classes.container}>{children}</Box>
    </Root>
  );
};
