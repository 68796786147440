import React from "react";
import { Box, Button, Typography, TableRow as MuiTableRow } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteUser, deleteEssay } from "./../../services";
import { classesTable, StylesMuiTableCell as MuiTableCellBody } from "../../../../../components/app/Table";

export const TableRow = (props) => {
  const { row, openDrawer, dataType, refreshList, onEditClick } = props; // Adicione onEditClick às props

  const handleDelete = async () => {
    try {
      if (dataType === "users") {
        await deleteUser(row.id);
      } else {
        await deleteEssay(row.id);
      }
      refreshList(); // Atualiza a lista após a exclusão
    } catch (error) {
      console.error("Erro ao deletar item:", error);
    }
  };

  const handleEdit = () => {
    if (dataType === "essays") {
      onEditClick(row.id); // Abre o modal de edição se for uma redação
    } else {
      openDrawer(row.id, dataType); // Caso contrário, abre a gaveta padrão
    }
  };

  return (
    < MuiTableRow
      sx={
        [
          {
            "&:hover td div.table-containerCellActive, &:hover td div.table-containerCell": {
              backgroundColor: (theme) => theme.palette.table.selected,
            },
          },
        ]}
    >
      {/* Nome do Usuário ou ID da Redação */}
      < MuiTableCellBody className={classesTable.root} align="left" >
        <Box display="flex" flexDirection="row" alignItems="center" width="100%">
          <Box component="div" className={classesTable.containerCellActive}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "280px",
                display: "block",
                overflow: "hidden",
              }}
            >
              {dataType === "users" ? row.name : row.user_name} {/* Exibe o nome do usuário ou ID da redação */}
            </Typography>
          </Box>
        </Box>
      </MuiTableCellBody >

      {/* Email do Usuário ou Conteúdo da Redação */}
      < MuiTableCellBody className={classesTable.root} align="center" >
        <Box component="div" className={classesTable.containerCell}>
          {dataType === "users" ? row.email : row.essay_title} {/* Exibe o email do usuário ou conteúdo da redação */}
        </Box>
      </MuiTableCellBody >

      {/* Tipo de Usuário ou ID do Modelo da Redação */}
      < MuiTableCellBody className={classesTable.root} align="center" >
        <Box component="div" className={classesTable.containerCell}>
          {dataType === "users" ? row.user_type : row.essay_average_grade} {/* Exibe o tipo de usuário ou ID do modelo da redação */}
        </Box>
      </MuiTableCellBody >

      {/* Ações */}
      < MuiTableCellBody className={classesTable.root} align="right" >
        <Box component="div" className={classesTable.containerCell}>
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            onClick={handleEdit} // Chama a função condicional de edição
          >
            Editar
          </Button>
          <Button
            color="secondary"
            variant="contained"
            endIcon={<DeleteIcon />}
            onClick={handleDelete}
            sx={{ ml: 2 }}
          >
            Deletar
          </Button>
        </Box>
      </MuiTableCellBody >
    </MuiTableRow >
  );
};

