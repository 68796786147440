import React from "react";

/* Material */
import { styled } from "@mui/material/styles";

const PREFIX = "AppForm";
const classes = {
  root: `${PREFIX}-root`,
};

const Form = styled("form")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
  },
}));

const FormControl = (props) => {
  const { children, ...other } = props;

  return (
    <Form className={classes.root} autoComplete="off" {...other}>
      {props.children}
    </Form>
  );
};

export default FormControl;
